<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",

  // mounted() {
  //   if (this._isMobile() && window.location.href != "https://wap.hfgw.info") {
  //     alert("手机端");
  //     // window.location.href = "https://wap.hfgw.info";
  //   } else {
  //     // alert("pc端");
  //     // window.location.href = "https://hfgw.info";
  //   }
  // },

  // methods: {
  //   _isMobile() {
  //     let flag = navigator.userAgent.match(
  //       /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  //     );
  //     return flag;
  //   },
  // },
};
</script>

<style lang="less" scoped>
</style>