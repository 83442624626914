import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//路由重复点击报错解决
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  console.log(location);
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/',
    name: 'Layout',
    redirect: '/home',
    component: () => import('@/views/layout'),
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home'),
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('@/views/about'),
      },
      {
        path: '/productcenter',
        name: 'ProductCenter',
        component: () => import('@/views/productcenter'),
        children: [
          {
            path: 'autotagging',
            name: 'AutoTagging',
            component: () => import('@/views/autotagging'),
          },
          {
            path: 'goodstracing',
            name: 'GoodsTracing',
            component: () => import('@/views/goodstracing'),
          },
          {
            path: 'cloudplatform',
            name: 'CloudPlatform',
            component: () => import('@/views/cloudplatform'),
          },
          {
            path: 'marketing',
            name: 'Marketing',
            component: () => import('@/views/marketing'),
          },
        ],
      },
      {
        path: '/application',
        name: 'Application',
        component: () => import('@/views/application'),
        children: [
          {
            path: 'foodindustry',
            name: 'FoodIndustry',
            component: () => import('@/views/foodindustry'),
          },
          {
            path: 'pharmaceutical',
            name: 'Pharmaceutical',
            component: () => import('@/views/pharmaceutical'),
          },
          {
            path: 'agriculture',
            name: 'Agriculture',
            component: () => import('@/views/agriculture'),
          },
          {
            path: 'cosmetics',
            name: 'Cosmetics',
            component: () => import('@/views/cosmetics'),
          },
          {
            path: 'building',
            name: 'BuildingMaterials',
            component: () => import('@/views/building'),
          },
          {
            path: 'dailychemicals',
            name: 'DailyChemicals',
            component: () => import('@/views/dailychemicals'),
          },
        ],
      },
      {
        path: '/contact',
        name: 'Contact',
        component: () => import('@/views/contact'),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
