import Vue from 'vue';
import App from './App.vue';
import router from './router';
import BaiduMap from 'vue-baidu-map';

//用于设置 rem 基准值
import 'amfe-flexible';

// es6 静态模块分析 ---> 打包使用的组件
import {
  Swipe,
  SwipeItem,
  Popover,
  Toast,
  Divider,
  Collapse,
  CollapseItem,
} from 'vant';
//重置样式文件
import '@/assets/style/base.css';
//动画样式文件
import animated from 'animate.css';

Vue.use(animated);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Divider);
Vue.use(Collapse);
Vue.use(CollapseItem);

Vue.use(Popover);
Vue.use(Toast);

Vue.config.productionTip = false;

Vue.use(BaiduMap, {
  ak: 'R7CTxkoAF9W4FDjlu54l9upKf3sMkeK1',
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
